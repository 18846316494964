import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import _debounce from "lodash/debounce";
import { useApi } from "components/hooks/useApi";
import Select from "react-select";
import { selectStyles } from "util/types";
import { classNames } from "util/common";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import GlobalSearchResults from "./GlobalSearchResults";
import { SearchIcon } from "@heroicons/react/outline";
import LoadingAnimation from "../LoadingAnimation";

const SEARCH_OPTIONS = [
	{ label: "מוצרים", value: "products" },
	{ label: "בתי מרקחת", value: "stores" },
	{ label: "ערים", value: "cities" },
	{ label: "גנטיקות", value: "strains" },
	{ label: "משווקים", value: "marketers" },
	{ label: "מגדלים", value: "manufacturers" },
	{ label: "סדרות", value: "product_series" },
	{ label: "מותגים", value: "manufacturer_series" },
];

function GlobalSearchBar({
	shouldFocus = false,
	disabled = false,
	timeoutMs = 750,
}) {
	const api = useApi();
	const [value, setValue] = useState("");
	const [category, setCategory] = useState(SEARCH_OPTIONS[0]);
	const [results, setResults] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const ref = useRef();
	const { collectAnalytics } = useContext(AnalyticsContext);
	const containerRef = useRef();

	useEffect(() => {
		if (shouldFocus) {
			ref.current.focus();
		}
	}, [shouldFocus]);

	const debouncedSearch = useCallback(
		_debounce(async query => {
			if (query !== null && typeof query === "string") {
				collectAnalytics("searchbar_query", { query });
				await fetchGlobalSearch(query);
			}
		}, timeoutMs),
		[timeoutMs, collectAnalytics, category],
	);

	const handleChange = e => {
		const newValue = e.target.value;
		setValue(newValue);

		if (!newValue?.length) {
			setIsLoading(false);
			setResults(null);
			return;
		}
		setIsLoading(true);
		debouncedSearch(newValue);
	};

	useEffect(() => {
		const handleClickOutside = event => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target)
			) {
				setResults(null);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [containerRef]);

	const fetchGlobalSearch = async query => {
		if (!query) return;
		try {
			const res = await api.globalSearch({
				category: category.value,
				tbl: { q: query },
				all_website_search: true,
			});
			if (res.ok) {
				setResults(res.data.items);
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div ref={containerRef} className="flex relative items-center w-full">
			<Select
				options={SEARCH_OPTIONS}
				classNames={{
					control: state =>
						state.isFocused ? "border-red-600" : "border-grey-300",
				}}
				styles={{
					...selectStyles,
					control: base => ({
						...base,
						width: 100,
						height: "39px",
						border: "1px solid #cbd5e1",
						borderRadius: "0px 8px 8px 0",
					}),
				}}
				theme={theme => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: "#f0f4f8",
						primary: "#64748b",
						primary50: "#e2e8f0",
					},
				})}
				value={category}
				onChange={category => {
					setResults(null);
					setCategory(category);
					ref.current.focus();
				}}
			/>
			<input
				ref={ref}
				type="search"
				className={classNames(
					"block w-full h-full border-r-0 border-jane-300 rounded-l-lg focus:border-jane-500 foucs:border-r-1 z-50 ring-jane-500 focus:ring-jane-500",
					!!disabled ? "bg-gray-100" : "",
				)}
				placeholder={"חיפוש לפי " + category.label}
				value={value}
				onChange={handleChange}
				disabled={disabled}
			/>
			<SearchIcon className="absolute left-4 h-5 w-5 text-jane-500 pointer-events-none" />

			{(!!results || isLoading) && (
				<div className="absolute top-full roght-0 w-full bg-white rounded-md border max-h-52 custom-scroll overflow-y-auto">
					{isLoading ? (
						<LoadingAnimation className="mx-auto" />
					) : (
						<GlobalSearchResults
							onResultClick={() => setResults(null)}
							results={results}
							category={category.value}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default GlobalSearchBar;
