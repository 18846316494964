import numeral from "numeral";
import { apiServerUrl } from "jane.config";
import { toast } from "react-toastify";
import {
	categoriesPercents,
	categoriesToShowFixedRange,
	listProductTypes,
	opt,
} from "./types";
import { useRouter } from "next/router";
import moment from "moment";
import { getAllFilterOptions } from "./commonFilters";

export const makeApiUrl = path => {
	return `${apiServerUrl}/api/${path}`;
};

export function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export function useIsTabletMode() {
	const router = useRouter();
	return router.pathname.includes("tablet");
}

export function toastMessage(message, type = "default") {
	const toastOptions = {
		rtl: true,
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		style: {
			zIndex: 999,
			borderRadius: "8px",
			width: "fit-content",
			padding: "2px",
			paddingLeft: "10px",
			minHeight: "40px",
			border: "solid 1px",
			marginBottom: "8px",
			maxWidth: "300px",
			textAlign: "end",
			borderColor:
				type === "error"
					? "#ff8f8f"
					: type === "success"
					? "#8fffa0"
					: type === "info"
					? "#3498db"
					: "#D1D5DB",
		},
	};
	return type === "error"
		? toast.error(message, toastOptions)
		: type === "success"
		? toast.success(message, toastOptions)
		: type === "info"
		? toast.info(message, toastOptions)
		: toast(message, toastOptions);
}

export function getCookie(name) {
	try {
		let cookieValue = null;
		if (document.cookie && document.cookie !== "") {
			const cookies = document.cookie.split(";");
			for (let i = 0; i < cookies.length; i++) {
				const cookie = cookies[i].trim();
				// Does this cookie string begin with the name we want?
				if (cookie.substring(0, name.length + 1) === name + "=") {
					cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
					break;
				}
			}
		}
		return cookieValue;
	} catch (e) {
		console.log(e);
	}
}

export function formatAmount(val) {
	if ([null, undefined].indexOf(val) !== -1) {
		return val;
	}
	if (val.toString().indexOf(".") === -1 && val.toString().length <= 2) {
		return val;
	}
	return numeral(val).format("0,0.[00]");
}

export function randStr(length = 10) {
	let result = [];
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	for (let i = 0; i < length; i++) {
		result.push(
			characters.charAt(Math.floor(Math.random() * characters.length)),
		);
	}
	return result.join("");
}

export const checkManufacturerOrMarketerName = heb_name => {
	if (heb_name) {
		return (
			<div className="text-[#1E293B] text-center text-xs sm:text-[14px] font-semibold leading-[13px] xs:leading-[13px] sm:leading-[16px] break-words">
				{heb_name.split(".").join("\n")}
			</div>
		);
	} else {
		return (
			<div className="text-[#1E293B] opacity-90 text-center text-xs sm:text-[14px] font-semibold leading-[13px] xs:leading-[13px] sm:leading-[16px] break-words">
				לא ידוע
			</div>
		);
	}
};

export const numberWithCommas = x => {
	return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const roundDecimal = number => {
	if (!number) return;

	// Check if the number has more than one decimal place
	if (number % 1 !== 0 && number.toString().split(".")[1].length > 1) {
		return Math.floor(number * 10) / 10;
	}
	return number;
};

export function isIncognitoMode() {
	try {
		localStorage.setItem(".", ".");
		localStorage.removeItem(".");
		return false;
	} catch (e) {
		return true;
	}
}
export let isIncognito = isIncognitoMode();

export function is48HoursPassed(date) {
	const now = new Date();
	const timeDiff = now.getTime() - date.getTime();
	const hoursPassed = timeDiff / (1000 * 60 * 60);
	//changed to 72
	if (hoursPassed >= 72) {
		return true;
	} else {
		return false;
	}
}

export function isiOSDevice() {
	return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function hasCharactersAfterStringInURL(url, targetString) {
	const position = url.indexOf(targetString);

	if (position !== -1) {
		const charactersAfterTarget = url.slice(position + targetString.length);
		return charactersAfterTarget.length > 0;
	}
	return false;
}

export function fillMissingFields(data, formFields) {
	// Returns -1 as value for key if value not provided
	const filledData = {};

	for (const field of formFields) {
		filledData[field] = data[field] !== undefined ? data[field] : -1;
	}

	return filledData;
}

export const isValidIsraeliID = id => {
	if (id.length > 9 || id.length < 5) return false;
	if (isNaN(id)) return false;

	if (id.length < 9) {
		while (id.length < 9) {
			id = "0" + id;
		}
	}
	if (id === "000000000") return false;
	return (
		/\d{9}/.test(id) &&
		Array.from(id, Number).reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		}) %
			10 ===
			0
	);
};

export const getHebLabel = type => {
	return listProductTypes.find(productType => {
		return productType.label === type;
	}).longLabel;
};

export const findTitleByValue = value => {
	for (const option of STORE_SORTBY_OPTIONS) {
		if (option.value === value) {
			return option.label;
		}

		if (option.options) {
			for (const subOption of option.options) {
				if (subOption.value === value) {
					return subOption.label;
				}
			}
		}
	}

	return null;
};

export const findTitleByValueProducts = value => {
	for (const option of PRODUCTS_SORTBY_OPTIONS) {
		if (option.value === value) {
			return option.label;
		}

		if (option.options) {
			for (const subOption of option.options) {
				if (subOption.value === value) {
					return subOption.label;
				}
			}
		}
	}

	return null;
};

export const LAYOUT = { GRID: "grid", LIST: "list" };

export const STORE_SORTBY_OPTIONS = [
	{
		value: "-",
		label: "סדר לפי",
	},
	{
		value: "store_price",
		label: "מחיר",
		options: [
			{ value: "store_price_least", label: "מחיר מהנמוך לגבוה" },
			{ value: "store_price_most", label: "מחיר מהגבוה לנמוך" },
		],
	},
	{
		value: "store_thc",
		label: "THC",
		options: [
			{ value: "store_percent_thc_least", label: "THC מהנמוך לגבוה" },
			{ value: "store_percent_thc_most", label: "THC מהגבוה לנמוך" },
		],
	},
	{
		value: "store_cbd",
		label: "CBD",
		options: [
			{ value: "store_percent_cbd_least", label: "CBD מהנמוך לגבוה" },
			{ value: "store_percent_cbd_most", label: "CBD מהגבוה לנמוך" },
		],
	},
	{ value: "product__heb_name", label: "א-ב" },
	{ value: "created_at", label: "חדשים" },
	{ value: "manufacturer__heb_name", label: "מגדלים" },
	{ value: "marketer__heb_name", label: "משווקים" },
	{ value: "store_product_date", label: "תוקף קצר" },
];

export const PRODUCTS_SORTBY_OPTIONS = [
	{
		value: "-",
		longLabel: "ברירת מחדל",
		label: "סדר לפי",
	},
	{
		value: "price",
		label: "מחיר",
		options: [
			{ value: "from_price", longLabel: "מחיר מהנמוך לגבוה", label: "מחיר" },
			{ value: "-to_price", longLabel: "מחיר מהגבוה לנמוך", label: "מחיר" },
		],
	},
	{
		value: "thc",
		label: "THC",
		options: [
			{
				value: "from_percent_thc",
				longLabel: "THC מהנמוך לגבוה",
				label: "THC",
			},
			{ value: "-to_percent_thc", longLabel: "THC מהגבוה לנמוך", label: "THC" },
		],
	},
	{
		value: "cbd",
		label: "CBD",
		options: [
			{
				value: "from_percent_cbd",
				longLabel: "CBD מהנמוך לגבוה",
				label: "CBD",
			},
			{ value: "-to_percent_cbd", longLabel: "CBD מהגבוה לנמוך", label: "CBD" },
		],
	},
	{ value: "heb_name", label: "א-ב" },
	{ value: "created_at", label: "חדשים" },
	{ value: "manufacturer__heb_name", label: "מגדלים" },
	{ value: "marketer__heb_name", label: "משווקים" },
];

export const dateConvert = date => {
	if (!!date) {
		const today = new Date(date);
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
		const yyyy = today.getFullYear();
		const yy = yyyy.toString().slice(-2);
		date = `${mm}/${yy}`;
		return date;
	}
};

//////////////////////////////
//////SEO AND XMLDocuments //
export const pt = t => {
	switch (t) {
		case "flower":
			return "תפרחת";
		case "oil":
			return "שמן";
		case "preroll":
			return "גלילות";
		default:
			return "";
	}
};

export const f = t => {
	switch (t) {
		case "indica":
			return "אינדיקה";
		case "sativa":
			return "סאטיבה";
		case "hybrid":
			return "היברידי";
		default:
			return "";
	}
};

export const baseUrl = {
	local: "http://localhost:3000",
	develop: "https://dev-jane.com",
	staging: "https://jane-stage.com",
	production: "https://jane.co.il",
}[process.env.ENV];

export function escapeXml(unsafe) {
	return unsafe.replace(/[<>&'"]/g, function (c) {
		switch (c) {
			case "<":
				return "&lt;";
			case ">":
				return "&gt;";
			case "&":
				return "&amp;";
			case "'":
				return "&apos;";
			case '"':
				return "&quot;";
		}
	});
}

export const getServerSiteMapResponse = async () => {
	let data = null;
	try {
		const resApi = await fetch(`${baseUrl}/api/common/sitemap/`);
		const contentType = resApi.headers.get("content-type");

		if (contentType && contentType.includes("application/json")) {
			data = await resApi.json();
		}
	} catch (error) {
		console.error("Fetch API error:", error);
		return { props: {} }; //
	}
	return data;
};

export const generateParamIdString = product => {
	if (product) {
		const { heb_name, eng_name, product_type, family, category } = product;
		return `${heb_name} (${eng_name}) - ${pt(product_type)} ${f(
			family,
		)} ${category} ${product?.marketer?.heb_name}`;
	}
};

export const getFiltersAndSearchQueryFromUrl = (router, cache) => {
	// Get all filter options (from all sources: products, stores, etc.)
	const allFilterOptions = getAllFilterOptions(cache);
	const { query } = router;
	if (!query) return;

	// Extract the filters from the query string
	let filters = query.filters?.split(";");
	const newFilters = filters?.map(filter => {
		const [property, condition, values] = filter.split("^");
		const value = values.split(",");

		const matchingFilter = allFilterOptions.find(f => f.property === property);
		let labels = [];
		if (property === "store_price" || property === "is_in_price_range") {
			labels = ["₪" + value[1] + " - " + "₪" + value[0]];
			value = [[...value]]; // we send to BE value: [[from, to]]
		} else if (!!matchingFilter && matchingFilter?.options) {
			labels = value.map(value => {
				return matchingFilter.options.find(option => option.value == value)
					?.label;
			});
		}

		return {
			property,
			labels,
			value,
			condition,
		};
	});

	const searchQuery = query.query;
	let sortBy = [];
	if (query.sortBy) {
		sortBy = [query.sortBy];
	}
	return { filters: newFilters, searchQuery, sortBy };
};

const urlsWebsocket = {
	test: "localhost:9040",
	development: "localhost:9040",
	production: "",
};

export const janeConfigWebsocket = {
	apiServerUrl: urlsWebsocket[process.env.NODE_ENV],
	productionUrl: "dashboard.jane.co.il",
	stagingUrl: "dashboard.jane-stage.com",
	devUrl: "dashboard.dev-jane.com",
};

export const SENT_VIA = {
	EMAIL: "email",
	SMS: "sms",
	SMS_API: "sms_api",
	WHATSAPP: "whatsapp",
};

export const getTodayDayOfWeek = () => {
	const date = new Date();
	const day = date.getDay();
	const dayNumber = day === 0 ? 1 : day + 1; // Adjust for Sunday being 1
	return dayNumber.toString(); // Convert the number to a string
};

export function formatDate(inputDate) {
	return moment(inputDate, "YYYY-MM-DD").format("DD.MM.YYYY");
}

export function formatPrice(price) {
	if (typeof price === "string") {
		price = parseFloat(price);
		if (isNaN(price)) {
			return price;
		}
	}

	// Round the price to two decimal places
	let roundedPrice = Math.round(price * 100) / 100;

	// Check if the rounded price is a whole number
	if (Number.isInteger(roundedPrice)) {
		return roundedPrice.toLocaleString("en-US");
	} else {
		// Format the rounded price with commas for thousands separator and two decimal places
		let formattedPrice = roundedPrice.toLocaleString("en-US", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formattedPrice;
	}
}

export function productAlt(product) {
	const hebName = product?.heb_name ? `${product.heb_name}` : "";
	const engName = product?.eng_name ? `- ${product.eng_name}` : "";
	return `אריזת המוצר ${hebName}${engName}`.trim();
}

export function logoAlt(store) {
	const hebName = store?.display_name
		? `${store.display_name}`
		: store?.heb_name
		? `${store.heb_name}`
		: "";
	const engName = store?.display_name_eng
		? ` - ${store.display_name_eng}`
		: store?.eng_name
		? ` - ${store.eng_name}`
		: "";
	return `לוגו ${hebName}${engName}`.trim();
}

export function clearTemporaryLinks(data) {
	for (const key in data) {
		if (key?.includes("url") && data[key]?.includes("Signature")) {
			delete data[key];
		}
	}
	return data;
}

export const getThcCbdRange = (product = {}, keyType = "thc" | "cbd") => {
	const category = product?.category;
	let value = swapPercents(categoriesPercents[category][keyType]);
	// Handle default range for new categories
	if (categoriesToShowFixedRange.includes(category)) {
		return value;
	}

	const {
		batch = {},
		[`store_percent_${keyType}`]: storePercent,
		[`from_percent_${keyType}`]: fromPercent,
		[`to_percent_${keyType}`]: toPercent,
		[`from_${keyType}`]: fromValue,
		[`to_${keyType}`]: toValue,
	} = product;

	const batchPercent = batch?.[`percent_${keyType}`];

	const from = fromPercent || fromValue || batchPercent || storePercent;
	const to = toPercent || toValue;

	if (!!to) {
		value = `${roundDecimal(to)}%`;
		if (!!from && from !== to) {
			value += `-${roundDecimal(from)}%`;
		}
	} else if (!!from) {
		value = `${roundDecimal(from)}%`;
	}

	return value;
};

export const swapPercents = percentString => {
	const [low, high] = percentString.split("-").map(str => str.trim());
	return `${high}-${low}`;
};

export const formatTerpeneList = terpenes => {
	if (!terpenes || terpenes.length === 0) return "לא ידוע";

	const allHavePercentage = terpenes.every(terpene => !!terpene?.percentage);
	const totalPercentage = terpenes.reduce(
		(sum, terpene) => sum + (parseFloat(terpene?.percentage) || 0),
		0,
	);
	const othersPercentage = Math.max(0, 100 - totalPercentage);

	let formattedList = terpenes
		.sort((a, b) => b.percentage - a.percentage)
		.map(terpene => {
			let terpeneText = terpene.heb_name;
			if (!!terpene?.percentage) {
				terpeneText += ` ${terpene.percentage}%`;
			}
			return terpeneText;
		})
		.join(", ");

	if (allHavePercentage && othersPercentage > 0 && othersPercentage < 100) {
		formattedList += `, אחרים ${othersPercentage}%`;
	}

	return formattedList;
};

export function splitPhoneByPrefix(phone) {
	phone = String(phone);
	const prefix = opt
		.filter(({ value }) => value !== "")
		.find(({ value }) => phone.startsWith(value))?.value;

	if (!prefix) {
		return null;
	}

	const phoneNumber = phone.slice(prefix.length);

	return {
		prefix,
		phoneNumber,
	};
}

export const getPrice = (from, to, catalog, i_a_f_s) => {
	if (!from && !to && !catalog) {
		return "-";
	}
	if (!!i_a_f_s && catalog) {
		return `₪${formatAmount(catalog)}`;
	}

	if (from && to && from !== to) {
		return `₪${formatAmount(to)} - ₪${formatAmount(from)}`;
	}
	if (from && to && from === to) {
		return `₪${formatAmount(from)}`;
	}
	if (!from || !to) {
		return `₪${formatAmount(catalog)}`;
	}
	if (((!from || !to) && !catalog) || (catalog === 0 && (!from || !to))) {
		return "לא ידוע";
	}

	return "-";
};
