import { apiServerUrl } from "jane.config";
import { makeApiUrl } from "util/common";
import { useHttp } from "./useHttp";

// TODO - NIV - allow other methods like patch / put / delete for more semantic API calls

export function useApi() {
	const { $get, $post } = useHttp();

	return {
		//* Common
		globalSearch: async params =>
			await $post(makeApiUrl("widget/allwebsite/"), params),
		getObjectsCache: async () => await $get(makeApiUrl(`cache/`)),
		getCities: async () => await $get(makeApiUrl(`common/cities/`)),
		getCitiesForFilter: async () =>
			await $get(makeApiUrl(`common/cities-for-filters/`)),
		getCitiesForFilterShabbat: async () =>
			await $get(makeApiUrl(`common/cities-for-filters/shabbat/`)),
		getCityById: async id => await $get(makeApiUrl(`widget/cities/${id}/`)),
		getCitiesByProductId: async id =>
			await $get(makeApiUrl(`widget/cities/product/${id}/`)),
		getSliderMessages: async () => await $get(makeApiUrl(`slider-messages/`)),

		//* S3
		getFileUrls: async params =>
			await $post(makeApiUrl(`account/get-file-urls/`), params),

		//* Auth
		getCsrfToken: async () => await $get(`${apiServerUrl}/api/common/csrf/`),
		checkEmailAvailability: async params =>
			await $post(makeApiUrl(`store/signup/check/`), params),
		submitRegistration: async params =>
			await $post(makeApiUrl(`store/signup/submit/`), params),
		submitLogin: async params =>
			await $post(makeApiUrl(`account/login/`), params),
		checkLogin: async () => await $get(makeApiUrl(`account/check/`)),
		submitLoginWithToken: async params =>
			await $post(makeApiUrl(`account/login-by-token/`), params),
		submitGoogleLogin: async params =>
			await $post(makeApiUrl(`login/google/`), params),
		submitLoginOtp: async params =>
			await $post(makeApiUrl(`account/login/otp/`), params),
		submitSignup: async params =>
			await $post(makeApiUrl(`account/signup/`), params),
		submitLogout: async () => await $post(makeApiUrl(`account/logout/`)),
		requestPhoneVerification: async () =>
			await $post(makeApiUrl(`account/verify/phone/request/`)),
		submitPhoneVerification: async params =>
			await $post(makeApiUrl(`account/verify/phone/submit/`), params),

		//* User || Account
		getUserInfo: async params => await $post(makeApiUrl(`shop/user/`), params),
		updateAccountInfo: async params =>
			await $post(makeApiUrl(`account/update-info/`), params),
		getAccountSideInfo: async () =>
			await $get(makeApiUrl(`get-account-side-info/`)),
		updateUserInfo: async params =>
			await $post(makeApiUrl(`shop/user/edit/`), params),
		requestEmailVerificationAfterChange: async params =>
			await $post(makeApiUrl(`account/change-email/otp-request/`), params),
		emailVerificationAfterChange: async params =>
			await $post(makeApiUrl(`account/change-email/otp-verify/`), params),
		requestPhoneVerificationAfterChange: async params =>
			await $post(makeApiUrl(`account/change-phone/otp-request/`), params),
		phoneVerificationAfterChange: async params =>
			await $post(makeApiUrl(`account/change-phone/otp-verify/`), params),
		checkAvailability: async params =>
			await $post(makeApiUrl("account/check-availability/"), params),

		//* Share documents
		getShareDocumentsTokenInfo: async token =>
			await $post(makeApiUrl(`account/get-token-info/${token}/`)),
		shareDocuments: async storeId =>
			await $post(makeApiUrl(`account/share-documents/${storeId}/`)),

		//* User adress
		getUserAddresses: async () => await $get(makeApiUrl(`account/address/`)),
		createUserAddress: async params =>
			await $post(makeApiUrl(`account/address/create/`), params),
		editUserAddress: async (addressId, params) =>
			await $post(makeApiUrl(`account/address/${addressId}/edit/`), params),
		deleteUserAddress: async addressId =>
			await $post(makeApiUrl(`account/address/${addressId}/delete/`)),
		updateHomeAddress: async params =>
			await $post(makeApiUrl(`account/update-address/`), params),
		getUserCitiesAddress: async () =>
			await $get(makeApiUrl(`cities/shipping-restrictions/`)),

		//* Presctiption
		getUserPrescriptions: async () =>
			await $get(makeApiUrl(`account/prescription/`)),
		createUserPrescription: async params =>
			await $post(makeApiUrl(`account/prescription/create/`), params),
		editUserPrescription: async (prescriptionId, params) =>
			await $post(
				makeApiUrl(`account/prescription/${prescriptionId}/edit/`),
				params,
			),
		getUserPrescription: async prescriptionId =>
			await $get(makeApiUrl(`account/prescription/${prescriptionId}/`)),
		softDeleteUserPrescription: async prescriptionId =>
			await $post(makeApiUrl(`account/prescription/${prescriptionId}/delete/`)),

		//* License
		getUserLicense: async licenseId =>
			await $get(makeApiUrl(`account/license/${licenseId}/`)),
		getUserLicenses: async () => await $get(makeApiUrl(`account/license/`)),
		createUserLicense: async params =>
			await $post(makeApiUrl(`account/license/create/`), params),
		editUserLicense: async (licenseId, params) =>
			await $post(makeApiUrl(`account/license/${licenseId}/edit/`), params),
		softDeleteUserLicense: async licenseId =>
			await $post(makeApiUrl(`account/license/${licenseId}/delete/`)),

		//* Order || Cart || Checkout
		getUserOrders: async id =>
			await $post(makeApiUrl(`account/orders/`), { tbl: { pn: 1, ps: 15 } }),
		submitUserOrder: async params =>
			await $post(makeApiUrl(`shop/order/new/`), params),
		cancelOrder: async params =>
			await $post(makeApiUrl(`shop/order/cancel/`), params),
		validateCartProducts: async params =>
			await $post(makeApiUrl(`common/validate-cart-products/`), params),

		//* Store
		getStore: async id => await $get(makeApiUrl(`stores/${id}/`)),
		getStoreCartProducts: async (id, params) =>
			await $post(makeApiUrl(`stores/${id}/cart-products/`), params),
		getStoreDiscounts: async (store_id, params) =>
			await $post(makeApiUrl(`${store_id}/discounts/get_all/`), params),
		getFullStoreDiscounts: async (store_id, params) =>
			await $post(makeApiUrl(`${store_id}/full-discounts/get_all/`), params),
		getStoreDiscountsApply: async (store_id, params) =>
			await $post(makeApiUrl(`${store_id}/discounts/apply/`), params),
		getStoreShop: async id => await $get(makeApiUrl(`shop/store/${id}/`)),
		getChainStores: async () => await $get(makeApiUrl(`common/chain-stores/`)),
		getStoreProduct: async (storeId, productId) =>
			await $get(makeApiUrl(`store-product-by-id/${storeId}/${productId}/`)),
		// Table endpoint but can give single product by params = {product_id: "id"}
		getWidgetStoreProducts: async (storeId, params) =>
			await $post(makeApiUrl(`widget/products/store/${storeId}/`), params),
		getAveragePriceWidget: async () =>
			await $get(makeApiUrl(`widget/avg-price`)),
		getStoresByDistrict: async (id, params) =>
			await $post(makeApiUrl(`stores/district/${id}/`), params),
		getStoreProducts: async (storeId, params) =>
			await $post(makeApiUrl(`common/store-products/${storeId}/`), params),
		getUserShippingRestrictions: async storeId =>
			await $post(makeApiUrl(`${storeId}/user-address/shipping-restrictions/`)),
		getStoreCityDeliveryInfo: async params =>
			await $post(makeApiUrl(`shop/distance/`), params),

		//* Product
		getProduct: async productId =>
			await $get(makeApiUrl(`widget/product/meta/${productId}/`)),
		getProductBatches: async (id, params) =>
			await $post(makeApiUrl(`batches-by-product/${id}/`), params),
		getSimilarStoreProducts: async (productId, storeId) =>
			await $get(
				makeApiUrl(`similar-products-by-strain/${productId}/${storeId}`),
			),
		getSimilarProducts: async (productId) =>
			await $get(
				makeApiUrl(`similar-products-by-strain/${productId}`),
			),
		//* Reviews
		getReviewQuestions: async id =>
			await $post(
				makeApiUrl(`product-review/get-active-questions-and-options/`),
			),
		createReview: async params =>
			await $post(makeApiUrl(`product-review/create-review/`), params),
		getReviewsByProduct: async id =>
			await $post(makeApiUrl(`product-review/get-reviews-by-product/${id}/`)),
		getReviewsByUser: async id =>
			await $post(makeApiUrl(`product-review/get-reviews-by-user/`)),
		getUserNotReviewedProductsAndBatches: async () =>
			await $post(makeApiUrl(`get-user-not-reviewed-products-and-batches/`)),
		getUserNotReviewedBatches: async id =>
			await $post(makeApiUrl(`get-user-not-reviewed-batches/${id}/`)),
		isVerifiedPurchase: async (id, params) =>
			await $post(
				makeApiUrl(`product-review/is-verified-purchase/${id}/`),
				params,
			),
		getBatchesByOrders: async id =>
			await $post(makeApiUrl(`batches-by-orders/${id}/`)),
		canLeaveReviewForBatch: async (batchId, params) =>
			await $post(makeApiUrl(`can-leave-review-for-batch/${batchId}/`), params),

		// address shipping-restrictions
		getShippingRestrictionCities: async (storeId, params) =>
			await $post(
				makeApiUrl(`stores/${storeId}/shipping-restrictions/`),
				params,
			),

		//* Strains
		getStrainParents: async id =>
			await $get(makeApiUrl(`common/get-strain-parents/${id}/`)),

		//* Product notifications
		createProductNotification: async params =>
			await $post(makeApiUrl(`product/create-notification/`), params),
		getProductNotifications: async params =>
			await $post(makeApiUrl(`product/get-notifications/`), params),
		editProductNotification: async (id, params) =>
			await $post(makeApiUrl(`product/edit-notification/${id}/`), params),
		deleteProductNotification: async (id, params) =>
			await $post(makeApiUrl(`product/delete-notification/${id}/`), params),

		//* Marketer || Manufacturer
		getMarketer: async (id, params) =>
			await $post(makeApiUrl(`widget/marketer/${id}/`), params),
		getManufacturer: async (id, params) =>
			await $post(makeApiUrl(`widget/manufacturer/${id}/`), params),

		//* User visits
		countUsersPageVisits: async id =>
			await $get(makeApiUrl(`latest-visits/${id}/`)),

		//* Wishlist
		getWishlist: async () => await $get(makeApiUrl(`account/wishlist/`)),
		addToWishlist: async params =>
			await $post(makeApiUrl(`account/wishlist/add/`), params),
		removeFromWishlist: async params =>
			await $post(makeApiUrl(`account/wishlist/remove/`), params),

		//* Not in use:
		getStoreOrders: async params =>
			await $post(makeApiUrl(`shop/user/orders/`), params),
		submitRecoveryPassword: async params =>
			await $post(makeApiUrl(`account/recovery/submit/`), params),
		submitMicrosoftLogin: async params =>
			await $post(makeApiUrl(`login/microsoft/`), params),
		submitEmailVerification: async params =>
			await $post(makeApiUrl(`account/verify/email/submit/`), params),
		changeAccountPassword: async params =>
			await $post(makeApiUrl(`account/change-password/`), params),
		updateAccountInfoInOrder: async params =>
			await $post(makeApiUrl(`account/update-info/order`), params),
		initPasswordRecovery: async params =>
			await $post(makeApiUrl(`account/recovery/init/`), params),
		checkRecoveryToken: async params =>
			await $post(makeApiUrl(`account/recovery/check/`), params),
		requestEmailVerification: async () =>
			await $post(makeApiUrl(`account/verify/email/request/`)),
	};
}
