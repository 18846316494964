import { PhotographIcon } from "@heroicons/react/outline";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { ProductTypeUI, VARIANT } from "components/products/ProductTypeUI";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { listProductTypes } from "util/types";
import ProductImage from "../ProductImage";

const ResultRow = ({
	link,
	handleClick,
	title,
	subtitle,
	imgUrl,
	noImage,
	children,
}) => {
	const router = useRouter();
	const basePath = router.pathname.replace(/\[.*\]$/, "");
	const isTheSamePage = link.startsWith(basePath);

	const content = children || (
		<div className="flex justify-between items-center">
			<div className="flex gap-2 items-center">
				{!noImage &&
					(imgUrl ? (
						<img src={imgUrl} className="w-10 h-10 rounded" alt={title} />
					) : (
						<PhotographIcon className="w-10 h-10 text-jane-400 rounded" />
					))}
				<div className="flex flex-col">
					<div className="text-jane-800 text-lg leading-5 font-semibold">
						{title}
					</div>
					<div className="text-jane-400">{subtitle}</div>
				</div>
			</div>
		</div>
	);

	if (isTheSamePage) {
		return (
			<a
				href={link}
				onClick={handleClick}
				className="bg-white px-2 py-1 transition-all hover:bg-jane-200"
			>
				{content}
			</a>
		);
	}

	return (
		<Link href={link} passHref>
			<a
				onClick={handleClick}
				className="bg-white px-2 py-1 transition-all hover:bg-jane-200"
			>
				{content}
			</a>
		</Link>
	);
};

function GlobalSearchResults({ category, onResultClick, results = [] }) {
	const { collectAnalytics } = useContext(AnalyticsContext);
	if (results.length === 0)
		return <p className=" text-center p-4 text-lg">אין תוצאות חיפוש</p>;
	return (
		<div className="flex gap-1 py-2 flex-col">
			{results.map(item => {
				switch (category) {
					case "products":
						return (
							<ResultRow
								link={`/products/${item.id}`}
								handleClick={() => {
									collectAnalytics("searchbar_product", {
										product_id: item.id,
									});
									onResultClick();
								}}
							>
								<div className="flex justify-between items-center">
									<div className="flex gap-2 items-center">
										<ProductImage
											product={item}
											src={item?.main_img_thumbnail_url}
											className="w-10 h-10 rounded"
										/>
										<div className="flex flex-col">
											<div className="text-jane-800 text-lg leading-5 font-semibold">
												{item?.heb_name}
											</div>
											<div className="text-jane-400">
												{item?.marketer_heb_name}
											</div>
										</div>
									</div>
									<div className="flex gap-0.5">
										<ProductTypeUI
											productType={listProductTypes.find(productType => {
												return productType.label === item.product_type;
											})}
											variant={VARIANT.SMALLTEXT}
										/>
										<ProductTypeUI
											productType={listProductTypes.find(productType => {
												return productType.label === item.category;
											})}
											variant={VARIANT.SMALLTEXT}
										/>
										<ProductTypeUI
											productType={listProductTypes.find(productType => {
												return productType.label === item.family;
											})}
											variant={VARIANT.SMALLTEXT}
										/>
									</div>
								</div>
							</ResultRow>
						);
					case "cities":
						return (
							<ResultRow
								link={`/cities/${
									!!item?.display_name_eng ? item.display_name_eng : item.id
								}`}
								handleClick={() => {
									collectAnalytics("searchbar_city", {
										city_id: item.id,
									});
									onResultClick();
								}}
								noImage
								title={item?.heb_name}
								subtitle={item?.eng_name}
							/>
						);
					case "stores":
						return (
							<ResultRow
								link={`/stores/${
									!!item?.display_name_eng ? item.display_name_eng : item.id
								}`}
								handleClick={() => {
									collectAnalytics("searchbar_store", {
										store_id: item.id,
									});
									onResultClick();
								}}
								imgUrl={item?.logo_thumbnail_url || item?.logo_url}
								title={item?.display_name}
								subtitle={item?.city?.heb_name}
							/>
						);
					case "marketers":
						return (
							<ResultRow
								link={`/marketers/${item.id}`}
								handleClick={() => {
									collectAnalytics("searchbar_marketer", {
										marketer_id: item.id,
									});
									onResultClick();
								}}
								imgUrl={item?.logo_url}
								title={item?.heb_name}
								subtitle={item?.eng_name}
							/>
						);
					case "manufacturers":
						return (
							<ResultRow
								link={`/manufacturers/${item.id}`}
								handleClick={() => {
									collectAnalytics("searchbar_manufacturer", {
										manufacturer: item.id,
									});
									onResultClick();
								}}
								imgUrl={item?.logo_url}
								title={item?.heb_name}
								subtitle={item?.eng_name}
							/>
						);
					case "strains":
						return (
							<ResultRow
								link={`/strains/${item.id}`}
								handleClick={() => {
									collectAnalytics("searchbar_strain", {
										strain_id: item.id,
									});
									onResultClick();
								}}
								noImage
								title={item?.heb_name}
								subtitle={item?.eng_name}
							/>
						);
					case "product_series":
						return (
							<ResultRow
								link={`/products/?filters=series_id^in^${item.id}`}
								handleClick={() => {
									collectAnalytics("searchbar_product_series", {
										series_id: item.id,
									});
									onResultClick();
								}}
								noImage
								title={item?.heb_name}
								subtitle={item?.eng_name}
							/>
						);
					case "manufacturer_series":
						return (
							<ResultRow
								link={`/products/?filters=manufacturer_series_id^in^${item.id}`}
								handleClick={() => {
									collectAnalytics("searchbar_manufacturer_series", {
										brand_id: item.id,
									});
									onResultClick();
								}}
								noImage
								title={item?.heb_name}
								subtitle={item?.eng_name}
							/>
						);
					default:
						return null;
				}
			})}
		</div>
	);
}

export default GlobalSearchResults;
