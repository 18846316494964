import {
	priceRanges,
	productCategoryOptions,
	productFamilyOptions,
	productTypeOptions,
} from "util/types";
import { COUNTRIES } from "./countryListIcons";
import { growTypeOptions, priceRangeOptions } from "./types";

export const getProductFilters = cache => [
	{ property: "is_in_price_range" },
	// {
	// 	property: "in_stock",
	// 	name: "הצגת מוצרים במלאי",
	// 	placeholder: "הצגת מוצרים במלאי",
	// 	type: "toggle",
	// 	condition: "in",
	// 	options: [{ value: "True", label: "במלאי" }],
	// 	isSearchableFilter: false,
	// },
	{
		name: "עיר",
		property: "store_products__store__city_id",
		placeholder: "עיר/ישוב",
		type: "select",
		condition: "in",
		isSearchableFilter: true,
		isMulti: true,
		condition: "in",
		showNum: "number_of_products",
		options:
			cache && cache.cities
				? cache.cities.map(x => ({
						value: x.id.toString(),
						label: x.heb_name,
						eng_name: x.eng_name,
						number_of_stores: x.num_stores,
						number_of_products: x.num_products,
				  }))
				: [], // Render an empty array if cache.cities doesn't exist yet
	},
	{
		name: "משווק",
		property: "marketer_id",
		placeholder: "משווק",
		type: "select",
		isSearchableFilter: true,
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.marketers || [])
				.sort((a, b) => a.heb_name.localeCompare(b.heb_name))
				.map(m => ({ value: m.id, label: m.heb_name, eng_name: m.eng_name }))
				.filter((m, i, arr) => arr.findIndex(m2 => m2.value === m.value) === i),
		],
	},
	{
		name: "מגדל",
		property: "manufacturer_id",
		placeholder: "מגדל",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.manufacturers || [])
				.sort((a, b) => a.heb_name.localeCompare(b.heb_name))
				.map(m => ({ value: m.id, label: m.heb_name, eng_name: m.eng_name }))
				.filter((m, i, arr) => arr.findIndex(m2 => m2.value === m.value) === i),
		],
	},
	{
		name: "ארץ ייצור",
		property: "origin_country",
		placeholder: "ארץ ייצור",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(COUNTRIES || [])
				.sort((a, b) => a.heb_name.localeCompare(b.heb_name))
				.map(x => ({
					label: x.heb_name,
					value: x.code,
					eng_name: x.eng_name,
					img_url: x.flagSVG,
				})),
		],
	},
	{
		name: "גנטיקה",
		property: "strain",
		placeholder: "גנטיקה",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.strains || [])
				.sort((a, b) => a.heb_name.localeCompare(b.heb_name))
				.map(x => ({
					label: `${x.heb_name}`,
					value: x.id,
					eng_name: x.eng_name,
				})),
		],
	},
	{
		name: "סוג גידול",
		property: "grow_type",
		placeholder: "מתקן גידול",
		isSearchableFilter: false,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(growTypeOptions || [])
				.sort((a, b) => a.label.localeCompare(b.label))
				.map(x => ({ label: `${x.label}`, value: x.value })),
		],
	},
	{
		name: "סדרות",
		property: "series_id",
		placeholder: "סדרה",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.series || []).map(series => ({
				label: series.heb_name,
				value: series.id,
				eng_name: series.eng_name,
			})),
		],
	},
	{
		name: "מותגים",
		property: "manufacturer_series_id",
		placeholder: "מותג",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.manufacturerSeries || []).map(series => ({
				label: series.heb_name,
				value: series.id,
				eng_name: series.eng_name,
			})),
		],
	},
	{
		name: " סמלילים ",
		property: "neutering_symbols__symbol__id",
		placeholder: "שיטת עיקור",
		isSearchableFilter: false,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.symbols[0].neutering || []).map(x => ({
				label: `${x.heb_name}`,
				value: x.id,
				eng_name: x.eng_name,
				img_url: x.img_url,
			})),
		],
	},
	{
		name: " סמלילים ",
		property: "measuring_symbols__symbol__id",
		placeholder: "שיטת מדידה",
		isSearchableFilter: false,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.symbols[1].measuring || []).map(x => ({
				label: `${x.heb_name}`,
				value: x.id,
				eng_name: x.eng_name,
				img_url: x.img_url,
			})),
		],
	},
	{
		name: " סמלילים ",
		property: "pest_symbols__symbol__id",
		placeholder: "שיטת הדברה",
		isSearchableFilter: false,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.symbols[2].pest || []).map(x => ({
				label: `${x.heb_name}`,
				value: x.id,
				eng_name: x.eng_name,
				img_url: x.img_url,
			})),
		],
	},
];

export const getProductQuickFilters = () => [
	{
		name: "סוג",
		property: "product_type",
		placeholder: "סוג",
		type: "select",
		condition: "in",
		// isMulti: true,
		condition: "in",
		options: [...productTypeOptions],
	},
	{
		name: "קטגוריה",
		property: "category",
		placeholder: "מינון",
		type: "select",
		condition: "in",
		// isMulti: true,
		condition: "in",
		options: [...productCategoryOptions],
	},
	{
		name: "אפיון",
		property: "family",
		placeholder: "אפיון",
		type: "select",
		condition: "in",
		// isMulti: true,
		condition: "in",
		options: [...productFamilyOptions],
	},
];

export const getStoresFilters = cache => [
	{
		name: "עיר למשלוח",
		property: "shipping_city_restrictions__city_id",
		placeholder: "ערים",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options:
			cache && cache.allCities
				? cache.allCities.map(c => ({
						label: c.heb_name,
						value: c.id,
				  }))
				: [],
	},
	{
		name: "עיר למשלוח",
		property: "store__shipping_city_restrictions__city_id",
		placeholder: "ערים",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options:
			cache && cache.allCities
				? cache.allCities.map(c => ({
						label: c.heb_name,
						value: c.id,
				  }))
				: [],
	},
	{
		name: "משלוח להיום/מחר",
		property: "store__today_or_tomorrow_shipping",
		options: [{ value: "True", label: "היום / מחר" }],
	},
	{
		name: "פתוח כעת",
		property: "is_active",
		options: [{ value: "True", label: "פתוח כעת" }],
	},
	{
		name: "פתוח כעת",
		property: "store__is_active",
		options: [{ value: "True", label: "פתוח כעת" }],
	},
];

export const getStoreFilters = cache => [
	{
		name: "טווח מחירים",
		property: "store_price",
		condition: "in_between",
	},
	{
		property: "is_discount_exists",
		name: "מבצעים",
		placeholder: "מבצעים",
		type: "toggle",
		condition: "in",
		options: [{ value: "True", label: "במבצע" }],
		isSearchableFilter: false,
	},
	{
		name: "משווק",
		property: "product__marketer_id",
		placeholder: "משווק",
		type: "select",
		isSearchableFilter: true,
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.marketers || [])
				.sort((a, b) => a.heb_name.localeCompare(b.heb_name))
				.map(m => ({ value: m.id, label: m.heb_name, eng_name: m.eng_name }))
				.filter((m, i, arr) => arr.findIndex(m2 => m2.value === m.value) === i),
		],
	},
	{
		name: "מגדל",
		property: "product__manufacturer_id",
		placeholder: "מגדל",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.manufacturers || [])
				.sort((a, b) => a.heb_name.localeCompare(b.heb_name))
				.map(m => ({ value: m.id, label: m.heb_name, eng_name: m.eng_name }))
				.filter((m, i, arr) => arr.findIndex(m2 => m2.value === m.value) === i),
		],
	},
	{
		name: "ארץ ייצור",
		property: "product__origin_country",
		placeholder: "ארץ ייצור",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(COUNTRIES || [])
				.sort((a, b) => a.heb_name.localeCompare(b.heb_name))
				.map(x => ({
					label: x.heb_name,
					value: x.code,
					eng_name: x.eng_name,
					img_url: x.flagSVG,
				})),
		],
	},
	{
		name: "גנטיקה",
		property: "product__strain",
		placeholder: "גנטיקה",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.strains || [])
				.sort((a, b) => a.heb_name.localeCompare(b.heb_name))
				.map(x => ({
					label: `${x.heb_name}`,
					value: x.id,
					eng_name: x.eng_name,
				})),
		],
	},
	{
		name: "סוג גידול",
		property: "product__grow_type",
		placeholder: "מתקן גידול",
		isSearchableFilter: false,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(growTypeOptions || [])
				.sort((a, b) => a.label.localeCompare(b.label))
				.map(x => ({ label: `${x.label}`, value: x.value })),
		],
	},
	{
		name: "סדרות",
		property: "product__series_id",
		placeholder: "סדרה",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.series || []).map(series => ({
				label: series.heb_name,
				value: series.id,
				eng_name: series.eng_name,
			})),
		],
	},
	{
		name: "מותגים",
		property: "product__manufacturer_series_id",
		placeholder: "מותג",
		isSearchableFilter: true,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.manufacturerSeries || []).map(series => ({
				label: series.heb_name,
				value: series.id,
				eng_name: series.eng_name,
			})),
		],
	},
	{
		name: " סמלילים ",
		property: "product__neutering_symbols__symbol__id",
		placeholder: "שיטת עיקור",
		isSearchableFilter: false,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.symbols[0].neutering || []).map(x => ({
				label: `${x.heb_name}`,
				value: x.id,
				eng_name: x.eng_name,
				img_url: x.img_url,
			})),
		],
	},
	{
		name: " סמלילים ",
		property: "product__measuring_symbols__symbol__id",
		placeholder: "שיטת מדידה",
		isSearchableFilter: false,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.symbols[1].measuring || []).map(x => ({
				label: `${x.heb_name}`,
				value: x.id,
				eng_name: x.eng_name,
				img_url: x.img_url,
			})),
		],
	},
	{
		name: " סמלילים ",
		property: "product__pest_symbols__symbol__id",
		placeholder: "שיטת הדברה",
		isSearchableFilter: false,
		type: "select",
		isMulti: true,
		condition: "in",
		options: [
			...(cache?.symbols[2].pest || []).map(x => ({
				label: `${x.heb_name}`,
				value: x.id,
				eng_name: x.eng_name,
				img_url: x.img_url,
			})),
		],
	},
];

export const getAllFilterOptions = cache => {
	// Fetch all filters
	const allProductFilters = getProductFilters(cache);
	const allQuickFilters = getProductQuickFilters();
	const additionalStoreFilters = getStoreFilters(cache);
	const storesQuickFilters = getStoresFilters(cache);

	// Merge all filters into one large array
	const allFilters = [
		...allProductFilters,
		...allQuickFilters,
		...additionalStoreFilters,
		...storesQuickFilters,
	];

	return allFilters;
};
