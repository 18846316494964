export const COUNTRIES = [
	{
		code: "IL",
		eng_name: "Israel",
		heb_name: "ישראל",
		alpha3: "ISR",
		flag: "🇮🇱",
		dialCode: "+972",
		flagSVG: "https://cdn.kcak11.com/CountryFlags/countries/il.svg",
	},
	{
		code: "CA",
		eng_name: "Canada",
		heb_name: "קנדה",
		alpha3: "CAN",
		flag: "🇨🇦",
		dialCode: "+1",
		flagSVG: "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
	},
	{
		code: "US",
		eng_name: "United States of America",
		heb_name: "ארה״ב",
		alpha3: "USA",
		flag: "🇺🇸",
		dialCode: "+1",
		flagSVG: "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
	},
	{
		code: "PT",
		eng_name: "Portugal",
		heb_name: "פורטוגל",
		alpha3: "PRT",
		flag: "🇵🇹",
		dialCode: "+351",
		isoCode: "PT",
		flagSVG: "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
	},
	{
		code: "UG",
		eng_name: "Uganda",
		heb_name: "אוגנדה",
		alpha3: "UGA",
		flag: "🇺🇬",
		dialCode: "+256",
		flagSVG: "https://cdn.kcak11.com/CountryFlags/countries/ug.svg",
	},
	{
		code: "UY",
		eng_name: "Uruguay",
		heb_name: "אורוגוואי",
		alpha3: "URY",
		flag: "🇺🇾",
		dialCode: "+598",
		flagSVG: "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
	},
	{
		code: "ZA",
		eng_name: "South Africa",
		heb_name: "דרום אפריקה",
		alpha3: "ZAF",
		flag: "🇿🇦",
		dialCode: "+27",
		flagSVG: "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
	},
	{
		code: "LS",
		eng_name: "Lesotho",
		heb_name: "לסוטו",
		alpha3: "LSO",
		flag: "🇱🇸",
		dialCode: "+266",
		flagSVG: "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
	},
];
